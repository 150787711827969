<template>
    <Typography :as="as" variant="label" :class="CSSClasses" class="eyebrow">
        <slot></slot>
    </Typography>
</template>

<script setup>
const props = defineProps({
    heading: {
        type: String,
        default: '',
    },
    as: {
        type: String,
        default: 'h6',
    },
    hasUnderline: {
        type: Boolean,
        default: false,
    },
    hasMargin: {
        type: Boolean,
        default: false,
    },
    isCentered: {
        type: Boolean,
        default: false,
    },
    opacity: {
        type: String,
        default: 'dark',
        validator: (value) => {
            return ['light', 'dark'].includes(value);
        },
    },
});
const CSSClasses = computed(() => {
    return [
        props.hasUnderline ? 'underlined' : null,
        props.hasMargin ? 'margin' : null,
        props.isCentered ? 'centered' : null,
        `opacity-${props.opacity}`,
    ];
});
</script>

<style lang="scss" scoped>
.eyebrow {
    position: relative;
    text-transform: uppercase;
    margin-bottom: vertical-space(1);

    &.margin {
        margin-bottom: vertical-space(4);
    }
    &.centered {
        text-align: center;

        &.underlined {
            &:after {
                left: 50%;
                right: auto;
                transform: translateX(-50%);
            }
        }
    }

    &.underlined {
        padding-bottom: 0.833333333333333em;

        &:after {
            content: '';
            position: absolute;
            height: 0.33em;
            width: 3.5em;
            background-color: color(teal);
            inset-inline-start: 0;
            top: 100%;
        }
    }

    &.light {
        opacity: 0.65;
    }
}
</style>
